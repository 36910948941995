<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="50%"
    @close="handleClose"
  >
    <el-form ref="formRef" :model="form" label-width="100px" :rules="rules">
      <el-form-item label="商品名称" prop="name">
        <el-input v-model="form.name" style="width: 400px"></el-input>

      </el-form-item>

      <el-form-item label="商品价格" prop="price">
        <el-input v-model="form.price" style="width: 100px"></el-input>
      </el-form-item>
      <el-form-item label="商品库存" prop="stock">
        <el-input v-model="form.stock" style="width: 100px"></el-input>
      </el-form-item>
      <el-form-item label="商品类别">
        <el-select v-model="bigTypeId" class="m-2" placeholder="请选择商品大类..." @change="handleBigTypeChange">
          <el-option
            v-for="item in bigTypeSlectOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        &nbsp;&nbsp;
        <el-select v-model="form.type.id" class="m-2" placeholder="请选择商品小类...">
          <el-option
            v-for="item in smallTypeSlectOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="商品描述" prop="description">
        <el-input
          v-model="form.description"
          :rows="4"
          type="textarea"
        />
      </el-form-item>

      <el-form-item label="商品介绍">
        <QuillEditor2 ref="myQuillEditor"
                     v-model:content="content"
                     contentType="html"
                     toolbar="full"
                     theme="snow"
                     :initialProductIntroImgs="form.productIntroImgs"
                     @updateImageUrl="handleImageUrlUpdate1"
                     :form="form"
        />
      </el-form-item>
      <el-form-item label="商品参数">
        <QuillEditor ref="myQuillEditor"
                     v-model:content="content"
                     contentType="html"
                     toolbar="full"
                     theme="snow"
                     :initialProductParaImgs="form.productParaImgs"
                     @updateImageUrl="handleImageUrlUpdate"
                     :form="form"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm"
        >确认保存</el-button
        >
      </span>
    </template>
  </el-dialog>


</template>

<script setup>
import { defineEmits, ref, defineProps, watch ,reactive} from "vue";
import axios from "@/util/axios";
import { ElMessage } from "element-plus";
import QuillEditor  from "@/components/Editor/index.vue";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import QuillEditor2  from "@/components/Editor/index2.vue";
const imageUrl1 = ref('')
const imageUrl = ref('')
const content = ref('');

// 当子组件传递过来的 imageUrl 更新时，更新父组件中的 imageUrl 并打印
const handleImageUrlUpdate = (value) => {
  imageUrl.value = value
  console.log('子组件传递过来的 imageUrl:', imageUrl.value)
}
// 添加新的图片更新方法
const handleImageUrlUpdate1 = (value) => {
  imageUrl1.value = value;
  console.log('子组件传递过来的新图片地址:', ImageUrl.value);
};


// const getChildContent = () => {
//   // 通过 $refs 获取子组件的实例
//   const quillEditor = $refs.myQuillEditor;
//   if (quillEditor) {
//     // 获取子组件的 content 值
//     content.value = quillEditor.content;
//
//   } else {
//     console.error('QuillEditor not found.');
//   }
// };


// const emailForm = reactive({
//   test_msg: ""
// });
// const getMsg = (val) => {
//   emailForm.msg = val;
// };
const props = defineProps({
  dialogTitle: {
    type: String,
    default: "",
    required: true
  },
  dialogValue: {
    type: Object,
    default: () => {
    }
  }
});
const bigTypeId = ref("");
//定义响应式变量ref
const form = ref({
  id: -1,
  name: "",
  price: 0,
  stock: 0,
  type: {
    id: ""
  },
  remark: "",
  productIntroImgs: "",
  productParaImgs: ""
});

const updateProductParaImgs = (val) => {
  form.productParaImgs = val;
};
const updateProductIntroImgs = (val) => {
  form.productIntroImgs = val;
};
const emits = defineEmits(["update:modelValue", "initProductList"]);
const formRef = ref(null);
const handleClose = () => {formRef.value.resetFields();emits("update:modelValue", false);};
const rules = ref({
  name: [
    {
      required: true,
      message: "请输入商品小类名称！"
    }
  ],
  price: [
    { required: true, message: "请输入商品价格!" },
    { type: "number", message: "商品价格是数值类型！", transform: (value) => Number(value) }
  ],
  stock: [
    { required: true, message: "请输入商品库存!" },
    { type: "number", message: "商品库存是数值类型！", transform: (value) => Number(value) }
  ],
  description: [
    {
      required: true,
      message: "请输入商品小类描述！"
    }
  ],
  productIntroImgs: [
    {
      required: true,
      message: "请输入商品介绍！"
    }
  ],
  productParaImgs: [
    {
      required: true,
      message: "请输入商品参数！"
    }
  ]
});
const bigTypeSlectOptions = ref([]);
const smallTypeSlectOptions = ref([]);
const initBigTypeSelectList = async () => {
  const res = await axios.post("admin/bigType/listAll");
  bigTypeSlectOptions.value = res.data.bigTypeList;
};
const initSmallTypeSelectList = async (bigTypeId) => {
  const res = await axios.post("admin/smallType/listAll/" + bigTypeId);
  smallTypeSlectOptions.value = res.data.smallTypeList;
};
initBigTypeSelectList();
const handleConfirm = () => {
  if (form.value.type.id == "") {
    ElMessage.error("请选择商品类别");
    return;
  }
  // 判断 imageUrl 是否不为空，然后赋值给 form.value.productParaImgs
  // if (imageUrl.value !== '') {
  //   form.value.productParaImgs = imageUrl.value;
  // }
  // if (imageUrl1.value !== '') {
  //   console.log("imageUrl1imageUrl1.value imageUrl1.value imageUrl1.value :" + imageUrl1.value );
  //   form.value.productIntroImgs = imageUrl1.value;
  // }
  // console.log("imageUrl1imageUrl1.value imageUrl1.value imageUrl1.value :" + imageUrl1.value );
  formRef.value.validate(async (valid) => {
    if (valid) {
      try {
        console.log("保存的数据为1:" + form.value.productParaImgs);
        console.log("保存的数据为2:" + form.value.productIntroImgs);

        let result = await axios.post("admin/product/save", form.value);
        //let result = ""
        let data = result.data;
        if (data.code == 0) {
          // 重置 form.productParaImgs
          content.value= '';
          form.value.productParaImgs = '';
          form.value = '';
          ElMessage.success("执行成功");
          formRef.value.resetFields();
          bigTypeId.value = "";
          emits("initProductList");
          handleClose();
        } else {
          ElMessage.error(data.msg);
        }
      } catch (err) {
        console.log("error:" + err);
        ElMessage.error("系统运行出错，请联系管理员");
      }
    } else {
      return false;
    }
  });
};
const handleBigTypeChange = (val) => {
  console.log("val=" + val);
  initSmallTypeSelectList(val);
};
const getBigTypeIdBySmallTypeId = async (smallTypeId) => {
  const res = await axios.get("admin/smallType/getBigTypeIdBySmallTypeId/" + smallTypeId);
  handleBigTypeChange(res.data.bigTypeId);
};
watch(
  () => props.dialogValue,
  () => {
    form.value = props.dialogValue;
    if (form.value.id == undefined) {
    } else {
      getBigTypeIdBySmallTypeId(form.value.type.id);
    }
  },
  { deep: true, immediate: true }
);

</script>
<style scoped>
</style>
