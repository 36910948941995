<template>
  <div>
    <QuillEditor ref="myQuillEditor"
                 theme="snow"
                 v-model:content="content"
                 :options="data.editorOption"
                 contentType="html"
                 @update:content="setValue()"
    />
    <!-- 使用自定义图片上传 -->
    <input type="file"
           hidden accept=".jpg,.png"
           ref="fileBtn"
           @change="handleUpload" />
  </div>
</template>

<script setup>
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { reactive, onMounted, ref, toRaw, watch } from 'vue'
import axios from "@/util/axios";
const imageUrl = ref('');//-------------
const form = props.form;//当父组件中的 form 发生变化时，子组件中的 form 也会相应地更新



const props = defineProps({
  value: {
    type: String,
    required: true
  },
  initialProductParaImgs: {
    type: String,
    default: ''
  },
  form: Object,

})
const emit = defineEmits(['updateValue'])
const content = ref('')
const myQuillEditor = ref()

watch(() => props.value, (val) => {
  console.log('props.value 发生变化:', val);
  content.value = val;
}, { deep: true })



watch(
  () => props.form.productParaImgs,
  (newValue, oldValue) => {
    console.log('form.productParaImgs 发生变化:', newValue);
    // 初始化编辑器
    const quill = toRaw(myQuillEditor.value).getQuill()
    if (myQuillEditor.value) {
      quill.getModule('toolbar').addHandler('image', imgHandler)
      // 将新的 form.productParaImgs 的值设置为编辑器的初始内容
      if (newValue) {
        quill.root.innerHTML = newValue
      }
    }
  },
  { deep: true }
);

const fileBtn = ref()
const data = reactive({
  content: '',
  editorOption: {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'header': 1 }, { 'header': 2 }],
        ['image'],
        [{ 'direction': 'rtl' }],
        [{ 'color': [] }, { 'background': [] }]
      ]
    },
    placeholder: "请输入、"

  }
})
const imgHandler = (state) => {
  if (state) {
    fileBtn.value.click()
  }
}
// 抛出更改内容，此处避免出错直接使用文档提供的getHTML方法
// const setValue = () => {
//   const text = toRaw(myQuillEditor.value).getHTML()
//   // 更新 v-model 绑定的内容
//   content.value = text
//   // 更新 form.productParaImgs 的值
//   form.productParaImgs = text;
// }
const setValue = () => {
  // 获取富文本编辑器中的 HTML 内容
  const htmlContent = toRaw(myQuillEditor.value).getHTML();

  // 使用正则表达式在 img 标签中添加 width="100%" 属性
  const modifiedHtmlContent = htmlContent.replace(/<img/g, '<img width="100%"');

  // 更新 v-model 绑定的内容
  content.value = modifiedHtmlContent;

  // 更新 form.productParaImgs 的值
  form.productParaImgs = modifiedHtmlContent;
}


const updateProductParaImgs = (val) => {
  form.productParaImgs = val;
};
const handleUpload = (e) => {
  console.log("handleUpload method called.")
  const files = Array.prototype.slice.call(e.target.files)
  if (!files) {
    return
  }
  const formdata = new FormData()
  formdata.append('file', files[0])

  axios.post('admin/product/uploadproductParaImgs', formdata)
    .then(res => {
      console.log("POST request to server successful.")
      if (res.data.data.src) {
        const quill = toRaw(myQuillEditor.value).getQuill()
        // 检查选择范围是否存在
        const selection = quill.getSelection()
        if (selection) {
          const length = selection.index
          // 插入图片到编辑器
          quill.insertEmbed(length, 'image', res.data.data.src)
          quill.setSelection(length + 1)
          // 将图片的在线地址保存到 content 变量中
          //content.value = "<p>"+`<img width="100%" src="${res.data.data.src}" >`+"</p>"
          // content.value = `<p><img width="100%" src="${res.data.data.src}"></p>`;
          // imageUrl.value = content.value// 存储图片地址
          // console.log("Received image src: " + imageUrl.value)
        } else {
          console.error("Selection is null.")
        }
      }
    })
    .catch(error => {
      console.error("Error sending POST request to server: ", error)
    })

}





// 在组件挂载后执行初始化编辑器的逻辑
onMounted(() => {
  const quill = toRaw(myQuillEditor.value).getQuill()
  if (myQuillEditor.value) {
    quill.getModule('toolbar').addHandler('image', imgHandler)
    // 将 form.productParaImgs 的值设置为编辑器的初始内容
    if (props.form.productParaImgs) {
      quill.root.innerHTML = props.form.productParaImgs
    }
  }
});
</script>
<style scoped lang="scss">
// 调整样式
:deep(.ql-editor) {
  min-height: 180px;
}
:deep(.ql-formats) {
  height: 21px;
  line-height: 21px;
}
</style>

